/* ============================================================================================== */
/* ======================================== ANTD IMPORT ======================================== */
/* ============================================================================================== */
@import '../node_modules/antd/dist/antd.min.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

/* ============================================================================================== */
/* ======================================== ANTD IMPORT ======================================== */
/* ============================================================================================== */

.btn-primary {
	@apply h-11 rounded border-0 bg-primary-800 px-6 font-inter font-bold text-white hover:bg-primary-800 hover:text-white focus:bg-primary-800 focus:text-white active:bg-primary-800 active:text-white;
}

.btn-primary-highlight {
	@apply h-11 rounded border-0 bg-primary-50 px-6 font-inter font-bold text-primary-800 hover:bg-primary-50 hover:text-primary-800 focus:bg-primary-50 focus:text-primary-800 active:bg-primary-50 active:text-primary-800;
}

.btn-header {
	@apply mr-3 h-full rounded-md border-0 shadow-none;
	@apply hover:text-primary-800;
	@apply active:text-primary-800;
	@apply focus:text-primary-800;
}

.btn-header-active {
	@apply btn-header font-bold;
	@apply bg-primary-50 text-primary-800;
	@apply hover:bg-primary-50 hover:text-primary-800;
	@apply active:bg-primary-50 active:text-primary-800;
	@apply focus:bg-primary-50 focus:text-primary-800;
}

.btn-place-order {
	@apply h-full rounded-md border-0 px-10 font-bold shadow-none;
	@apply bg-green-500 text-white;
	@apply hover:bg-green-500 hover:text-white;
	@apply active:bg-green-500 active:text-white;
	@apply focus:bg-green-500 focus:text-white;
}

.btn-ghost {
	@apply flex w-max items-center border-0 bg-transparent p-0 text-sm text-primary-800 underline shadow-none;
	@apply text-primary-800;
	@apply hover:text-primary-800 hover:!underline;
	@apply active:text-primary-800 active:underline;
	@apply focus:text-primary-800 focus:underline;
}

.btn-dashed {
	@apply flex w-max items-center rounded-full border border-dashed text-base font-bold shadow-none;
	@apply border-primary-800 bg-primary-50 text-primary-800;
	@apply hover:border-primary-800 hover:bg-primary-50 hover:text-primary-800;
	@apply active:border-primary-800 active:bg-primary-50 active:text-primary-800;
	@apply focus:border-primary-800 focus:bg-primary-50 focus:text-primary-800;
}

.btn-delete {
	@apply flex w-max items-center rounded border text-sm font-bold shadow-none;
	@apply border-red-500 bg-red-50 text-red-500;
	@apply hover:border-red-500 hover:bg-red-50 hover:text-red-500;
	@apply active:border-red-500 active:bg-red-50 active:text-red-500;
	@apply focus:border-red-500 focus:bg-red-50 focus:text-red-500;
}

.flex-column-box {
	@apply flex flex-col items-center justify-center;
}

.input-field {
	@apply flex h-12 items-center rounded border-gray-400 font-inter;
}

.counter-button {
	@apply absolute z-10;
}

.left-counter-button {
	@apply counter-button;
	left: 0px;
	margin-top: 8px;
	margin-left: 20px;
	background: white;
}
.right-counter-button {
	@apply counter-button;
	right: 0px;
	margin-top: 8px;
	margin-right: 20px;
	background: white;
}

/* ============================================================================================== */
/* ======================================== FONT IMPORTS ======================================== */
/* ============================================================================================== */
/* @font-face {
	font-family: 'Regular';
	src: url('assets/fonts/ProximaNova-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Bold';
	src: url('assets/fonts/ProximaNova-Bold.woff') format('woff');
	font-weight: normal;
	font-style: normal;
} */

/* 
@font-face {
	font-family: 'Medium';
	src: url('assets/fonts/Inter-Medium.woff') format('woff');
	font-weight: normal;
	font-style: normal;
} */

@font-face {
	font-family: 'Thin';
	src: url('assets/fonts/Inter-Thin.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Regular';
	src: url('assets/fonts/Inter-Medium.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Bold';
	src: url('assets/fonts/Inter-Bold.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

/* ============================================================================================== */
/* ======================================= HELPER CLASSES ======================================= */
/* ============================================================================================== */
.ListButton:hover{
	background-color: #00000014;
}

.FullWidthButton:hover{
	background-color: #e5f2ff;
}
.FullWidthHeight {
	width: 100%;
	height: 100%;
}
.TwoHundredWidth {
	width: 200px;
}
.FourHundredWidth {
	width: 400px;
}
.NinteyTenHeight {height: 90%; }

.FullWidth {
	width: 100%;
}
.HalfWidth {
	width: 50%;
}
.QuarterWidth {
	width: 25%;
}
.SixtyFourtyWidth {
	width: 60% !important;
}
.FourtySixtyWidth {
	width: 40% !important;
}
.SeventyThirtyWidth {
	width: 70% !important;
}
.ThirtySeventyWidth {
	width: 30% !important;
}
.NinetyTenWidth {
	width: 100% !important;
}
.ContentWidth {
	width: fit-content;
}

.TextShadow {
	text-shadow: 1px 1px #ff0000;
}

.PageCenter {
	position: absolute;
	width: 300px;
	height: 200px;
	z-index: 15;
	top: 50%;
	left: 50%;
	margin: -100px 0 0 -150px;
}

.FullHeight {
	height: 100%;
}
.HalfHeight {
	height: 50%;
}
.ThreeFourthWidth {
	width: 75%;
}
.QuarterHeight {
	height: 25%;
}

.HalfMaxHeight {
	max-height: 50%;
}
.QuarterMaxHeight {
	max-height: 25%;
}

.Height72 {
	height: 72px;
}
.Height48 {
	height: 48px;
}

.DisplayFlex {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
}
.DisplayFlexSpace {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
}
.FlexRow {
	display: flex;
	align-items: center;
}
.FlexRowStart {
	display: flex;
	align-items:flex-start;
}
.FlexRowBetween{
	display: flex;
	align-items:flex-start;
	justify-content:space-between;
}
.FlexColumn {
	display: flex;
	flex-direction: column;
}
.FlexRowBox {
	display: flex;
	align-items: center;
	justify-content: center;
}
.FlexColumnBox {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.FlexRowSpace {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.FlexColumnSpace {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.FlexRowEnd {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}
.FlexRowSpaceEven {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

.NoMargin {
	margin: 0 !important;
}
.Margin {
	margin: 24px;
}
.MarginTop {
	margin-top: 24px;
}
.MarginTopError { margin-top: 80px; }
.MarginBottom {
	margin-bottom: 24px;
}
.MarginLeft {
	margin-left: 24px;
}
.MarginRight {
	margin-right: 24px;
}
.HalfMargin {
	margin: 12px;
}
.HalfMarginTop {
	margin-top: 12px;
}
.HalfMarginBottom {
	margin-bottom: 12px;
}
.HalfMarginLeft {
	margin-left: 12px;
}
.HalfMarginRight {
	margin-right: 12px;
}
.QuarterMarginTop {
	margin-top: 6px;
}
.QuarterMarginBottom {
	margin-bottom: 6px;
}
.QuarterMarginLeft {
	margin-left: 6px;
}
.QuarterMarginRight {
	margin-right: 6px;
}
.QuarterMargin{
	margin: 4px;
}
.MarginRight8 {
	margin-right: 8px;
}
.MarginLeftRight {
	margin: 0px 24px;
}
.HalfMarginTopBottom {
	margin: 12px 0px;
}
.HalfMarginLeftRight {
	margin: 0px 12px;
}
.MarginTopBottom {
	margin: 24px 0px;
}

.NoPadding {
	padding: 0 !important;
}
.Padding {
	padding: 24px;
}
.PaddingTop {
	padding-top: 24px;
}
.PaddingBottom {
	padding-bottom: 24px;
}
.PaddingLeft {
	padding-left: 24px;
}
.PaddingRight {
	padding-right: 24px;
}
.HalfPadding {
	padding: 12px;
}
.HalfPaddingTop {
	padding-top: 12px;
}
.HalfPaddingBottom {
	padding-bottom: 12px;
}
.HalfPaddingLeft {
	padding-left: 12px;
}
.HalfPaddingRight {
	padding-right: 12px;
}
.QuarterPadding {
	padding: 6px;
}
.QuarterPaddingTop {
	padding-top: 6px;
}
.QuarterPaddingBottom {
	padding-bottom: 6px;
}
.QuarterPaddingLeft {
	padding-left: 6px;
}
.QuarterPaddingRight {
	padding-right: 6px;
}

.PaddingTopBottom {
	padding: 24px 0px;
}
.PaddingLeftRight {
	padding: 0px 24px;
}
.HalfPaddingLeftRight {
	padding: 0px 12px;
}
.PaddingExceptBottom {
	padding: 24px;
	padding-bottom: 0px;
}
.HalfPaddingExceptBottom {
	padding: 12px;
	padding-bottom: 0px;
}
.HalfPaddingTopBottom {
	padding: 12px 0px;
}
.HalfPaddingLeftRight {
	padding: 0px 12px;
}
.QuarterPaddingTopBottom {
	padding-top: 6px;
	padding-bottom: 6px;
}
.QuarterPaddingLeftRight {
	padding-left: 6px;
	padding-right: 6px;
}
.QuarterPaddingTopRight{
	padding: 6px 6px 0px 0px;
}
.HalfPaddingExceptTop {
	padding: 0px 12px 12px 12px;
}
.PaddingExceptTop {
	padding: 0px 24px 24px 24px;
}

.FooterPadding { padding: 8px 6px 8px 6px }

.PrimaryColor {
	color: #0066cc;
}
.PrimaryShadeColor {
	color: #003366;
}
.PrimaryTintColor {
	color: #3399ff;
}
.PrimaryHighlightColor {
	color: #e5f2ff;
}
.SecondaryColor {
	color: #00cbcb;
}
.SecondaryShadeColor {
	color: #009999;
}
.SecondaryTintColor {
	color: #00ffff;
}
.SecondaryHighlightColor {
	color: #e5ffff;
}
.GrayColor {
	color: #808080;
}
.GrayShadeColor {
	color: #666666;
}
.GrayTintColor {
	color: #999999;
}
.GrayHighlightColor {
	color: #f2f2f2;
}
.BlackColor {
	color: #333333;
}
.WhiteColor {
	color: #ffffff;
}
.ErrorColor {
	color: #cc0000;
}
.SuccessColor {
	color: #00800b;
}
.YellowColor {
	color: #ff9500;
}
.WhiteColor {
	color: #ffffff;
}

.PBackground {
	background-color: #1c75bc;
}
.PSBackground {
	background-color: #003366;
}
.PTBackground {
	background-color: #3399ff;
}
.PHBackground {
	background-color: #e5f2ff;
}
.SHBackground {
	background-color: #e5ffff;
}
.GBackground {
	background-color: #808080;
}
.GHBackground {
	background-color: #f2f2f2 !important;
}
.WBackground {
	background-color: #ffffff;
}
.TBackground{
	background-color: #00000014;
}
.EHBackground {
	background-color: #ffe5e6;
}
.SBackground {
	background-color: #00800b;
}
.EBackground {
	background-color: #ff0000;
}
.RBackground {
	background-color: #ff9999;
}

.ThinFont {
	font-family: 'Thin';
}
.RegularFont {
	font-family: 'Regular';
}
.BoldFont {
	font-family: 'Bold';
}
.FontSize10 {
	font-size: 10px;
}
.FontSize12 {
	font-size: 12px;
}
.FontSize14 {
	font-size: 14px;
}
.FontSize16 {
	font-size: 16px;
}
.FontSize18 {
	font-size: 18px;
}
.FontSize20 {
	font-size: 20px;
}
.Capitalize {
	text-transform: capitalize;
}
.Italic {
	font-style: italic;
}
.LeftAlign {
	text-align: left;
}
.RightAlign {
	text-align: right;
}
.CenterAlign {
	text-align: center;
}

.NoBorder {
	border: 0px;
}
.Border {
	border: 1px solid #e5e5e5;
}
.BorderTop {
	border-top: 1px solid #e5e5e5;
}
.BorderBottom {
	border-bottom: 1px solid #e5e5e5;
}
.BorderLeft {
	border-left: 1px solid #e5e5e5;
}
.BorderRight {
	border-right: 1px solid #e5e5e5;
}
.BorderRadius {
	border-radius: 5px;
}
.BorderRadiusMed {
	border-radius: 20px;
}
.BorderRadiusSmall{
	border-radius: 10px;
}
.BorderRadiusCircle {
	border-radius: 100px;
}
.NoBorderRadius {
	border-radius: 0px !important;
}
.BorderRadiusTop {
	border-radius: 5px 5px 0px 0px !important;
}
.BorderRadiusBottom {
	border-radius: 0px 0px 5px 5px !important;
}
.BorderRadiusTopMedium {
	border-radius: 20px 20px 0px 0px !important;
}
.BorderRadiusTopSmall{
	border-radius: 10px 10px 0px 0px !important;
}
.BorderRadiusBottomMedium {
	border-radius: 0px 0px 20px 20px !important;
}
.BorderRadiusBottomSmall{
	border-radius: 0px 0px 10px 10px !important;

}
.CircleRadius {
	aspect-ratio: 1/1;
	border-radius: 100px;
}

.NoOpacity {
	opacity: 0;
}
.OverflowScroll {
	overflow-y: scroll;
}
.OverflowScrollX {
	overflow-x: scroll;
}
.CursorPointer {
	cursor: pointer;
}
.Link {
	text-decoration: underline;
	cursor: pointer;
}
.DisplayNone {
	display: none;
}
.NoWrap {
	white-space: nowrap;
}

.PositionRelative {
	position: relative;
}
.PositionAbsolute {
	position: absolute;
}

/* ============================================================================================== */
/* ======================================== BASE CLASSES ======================================== */
/* ============================================================================================== */
html,
body,
#root {
	width: 100vw;
	height: 100vh;
	font-family: 'Regular' !important;
	font-size: 14px;
	color: #333333 !important;
	padding: 0px;
	margin: 0px;
	overscroll-behavior: none;
	/* user-select: none; */
}

p {
	margin: 0px;
	color: #333333;
}
a:hover {
	text-decoration: none;
	color: #0066cc;
}

/* ============================================================================================== */
/* ======================================= LAYOUT CLASSES ======================================= */
/* ============================================================================================== */
.ant-layout {
	width: 100%;
	height: 100%;
}
.ant-layout-header {
	height: auto;
	background-color: #ffffff;
	line-height: 120%;
}

/* ============================================================================================== */
/* ======================================= HEADER CLASSES ======================================= */
/* ============================================================================================== */
.PrimaryHeader.ant-layout-header {
	height: 65px;
}
.SecondaryHeader.ant-layout-header {
	height: 60px;
}
.NoPadding.ant-layout-header {
	padding: 0%;
}

.ant-breadcrumb-separator {
	font-family: 'Bold';
	font-size: 18px;
}

.HeaderLogo {
	width: auto;
	height: 30px;
}

.ProfileInfo {
	height: 36px;
	border-radius: 100px;
}

.ProfileMenu {
	border-radius: 10px;
}
.ProfileMenu > .ant-dropdown-menu-item {
	height: 36px;
	display: flex;
	align-items: center;
}
.ProfileMenu > .ant-dropdown-menu-item:first-child {
	border-radius: 10px 10px 0px 0px;
}
.ProfileMenu > .ant-dropdown-menu-item:last-child {
	border-radius: 0px 0px 10px 10px;
}

.ant-dropdown-menu-title-content {
	display: flex;
	align-items: center;
}

/* ============================================================================================== */
/* ======================================= SIDEBAR CLASSES ====================================== */
/* ============================================================================================== */
.ant-layout-sider {
	height: 100%;
	background-color: #ffffff;
	border-right: 1px solid #e5e5e5;
}

.SidebarIcon {
	width: 20px;
	height: 20px;
	margin-right: 12px;
	fill: #0066cc;
}

.ant-menu-item {
	height: 60px !important;
	font-family: 'Bold';
	margin: 0px !important;
	padding: 0px !important;
	padding-left: 16px !important;
	padding-right: 16px !important;
}
.ant-menu-inline {
	border: 0px;
}
.ant-menu-inline .ant-menu-item::after {
	border-right: 3px solid #0066cc;
}
.ant-menu-item-selected {
	background: #e5f2ff;
}

/* ============================================================================================== */
/* ============================================ TABS ============================================ */
/* ============================================================================================== */
.ant-tabs-tab,
.ant-tabs-nav {
	font-family: 'Bold';
	font-size: 12px;
	margin: 0px !important;
	border-left: 0px !important;
	border-top: 0px !important;
	border-right: 1px solid #e0e0e0;
	border-radius: 0px !important;
}

/* ============================================================================================== */
/* ======================================= TABLE SETTINGS ======================================= */
/* ============================================================================================== */
.ant-table {
	margin: 0px !important;
}
.ant-table-thead > tr > th {
	font-family: 'Bold';
	font-size: 12px;
}
.ant-table-column-title {
	font-size: 12 !important;
}

.ant-table-expanded-row > .ant-table-cell {
	padding: 0px !important;
}
td.ant-table-column-sort {
	background: #ffffff;
}

.ant-table-wrapper > div,
.ant-table-wrapper > div > div,
.ant-table-wrapper > div > div > div,
.ant-table-wrapper > div > div > div > div {
	height: 100%;
}

#inheritSpan span.ant-radio + span {
    padding-right: 8px;
    padding-left: 8px;
    width: inherit;
}

.MainTable {
	height: calc(100% - 50px);
}
.MainTable .ant-table-thead > tr > th {
	color: #333333;
	background-color: #f2f2f2;
}
.MainTable .ant-table-thead th.ant-table-column-has-sorters:hover {
	background-color: #f2f2f2;
}

.ant-table-tbody > tr.ant-table-row-selected > td,
.ant-table-tbody > tr.ant-table-row-selected:hover > td {
	background-color: #f5faff;
}

.SelectedRow.GHBackground td,
.SelectedRow.GHBackground td:hover {
	background: #f2f2f2 !important;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td {
	border-right: 0px;
}

.ant-table.ant-table-small .ant-table-tbody > tr > td {
	padding: 16px 16px;
}

/* ============================================================================================== */
/* ======================================= CARD SETTINGS ======================================= */
/* ============================================================================================== */
.ant-card {
	margin-bottom: 24px;
	border-radius: 5px;
}
.ant-card-head {
	font-family: 'Bold';
	padding-left: 24px !important;
	padding-right: 24px !important;
	border-radius: 5px 5px 0px 0px;
}
.ant-card-head-title {
	color: #0066cc;
}
.ant-card-body {
	padding: 24px !important;
	border-radius: 0px 0px 5px 5px;
}

.ant-divider-dashed {
	border-color: #9f9f9f;
}

/* ============================================================================================ */
/* ======================================== FORM ITEMS ======================================== */
/* ============================================================================================ */
.ant-form-item-label {
	/* font-family: 'Bold'; */
	padding-bottom: 4px !important;
}
.ant-form-item-label > label {
	font-size: 12px;
}
.ant-form-item.InheritWidth {
	width: inherit;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
	display: none;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
	display: inline-block;
	content: '*';
	color: #ff0000;
	margin-left: 4px;
}

.InputField > .ant-input-group,
.InputNumberField .ant-input-number-group,
.InputNumberField .ant-input-number-input-wrap,
.InputNumberField input {
	height: 100%;
}
.InputField .ant-input-group-addon,
.InputNumberField .ant-input-number-group-addon {
	height: 100%;
	font-family: 'Regular';
	color: #333333;
	background-color: #f2f2f2;
	border: 0;
	padding: 0px 12px;
}
.InputField .ant-input,
.InputNumberField .ant-input-number {
	height: 100%;
	border: 0;
}
.InputField > .ant-input-group > *:first-child,
.InputNumberField > .ant-input-number-group > *:first-child {
	border-radius: 5px 0px 0px 5px !important;
}
.InputField > .ant-input-group > *:last-child,
.InputNumberField > .ant-input-number-group > *:last-child {
	border-radius: 0px 5px 5px 0px !important;
}

.ant-form-item-has-error .InputField {
	border: 1px solid #ff0000 !important;
}
.ant-form-item-has-error .InputNumberField.ant-input-number-group-wrapper {
	border: 1px solid #ff0000 !important;
}
.ant-form-item-has-error > .ant-form-item-control > div > div > span > .UploadImages {
	border: 1px dashed #ff0000 !important;
}
.ant-form-item-has-error .UploadImages .ant-btn {
	border: 1px solid #ff0000 !important;
}

li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child > span.ant-dropdown-menu-title-content {
	justify-content: center;
}

.BoxShadow{
	box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12)
}

/* ============================================================================================== */
/* ======================================== INPUT FIELDS ======================================== */
/* ============================================================================================== */
.InputField,
.SelectField > .ant-select-selector,
.ant-select-selection-search-input,
.InputNumberField.ant-input-number-group-wrapper {
	/* width: 100%; */
	height: 45px;
	min-height: 45px !important;
	font-family: 'Regular';
	display: flex;
	align-items: center;
	border: 1px solid #e0e0e0;
	border-radius: 5px !important;
	color: #333333;
}

.SearchField {
	color: #333333;
	background-color: #f8f8f8;
	border: 1px solid #e0e0e0;
	border-radius: 10px !important;
}
.SearchField .ant-input {
	width: 50%;
	height: 50px !important;
	background-color: #f8f8f8;
}

.InputField > .ant-input-number-input-wrap {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
}

.TextAreaField {
	min-height: 72px !important;
}
.TypeField, .SelectField > .ant-select-selector {
	width: 100%;
	height: 48px !important;
	display: flex;
    align-items: center;
	border: 1px solid #BFBFBF !important;
	border-radius: 5px !important;
	color: #333333;
}
.Button,
.Button:hover,
.Button:active,
.Button:focus {
	min-width: max-content;
	max-height: 48px;
	font-family: 'Bold';
	/* font-size: 14px; */
	color: #ffffff;
	padding: 0px 24px;
	border: 0;
	border-radius: 5px;
}

.ant-form-vertical .ant-form-item-label > label {
	@apply text-gray-500 font-semibold text-sm mb-1
	/* color: #787878;
	font-weight: 600;
	font-size: 12px;
	margin-bottom: 2px; */
}

.TooltipButton,
.TooltipButton:hover,
.TooltipButton:active,
.TooltipButton:focus {
	min-width: max-content;
	max-height: 48px;
	font-family: 'Bold';
	/* font-size: 14px; */
	color: #ffffff;
	border: 0;
	border-radius: 5px;
}

.PrimaryButton,
.PrimaryButton:hover,
.PrimaryButton:active,
.PrimaryButton:focus {
	color: #ffffff;
	background-color: #0066cc;
}
.SecondaryButton,
.SecondaryButton:hover,
.SecondaryButton:active,
.SecondaryButton:focus {
	color: #333333;
	background-color: #f2f2f2;
}
.PHButton,
.PHButton:hover,
.PHButton:active,
.PHButton:focus {
	background-color: #e5f2ff;
}
.EHButton,
.EHButton:hover,
.EHButton:active,
.EHButton:focus {
	background-color: #ffe5e6;
	color: #ff0000;
}
.SuccessButton,
.SuccessButton:hover,
.SuccessButton:active,
.SuccessButton:focus {
	background-color: #00800b;
}
.DynamicButton,
.DynamicButton:hover,
.DynamicButton:active,
.DynamicButton:focus {
	width: max-content;
	font-family: 'Regular';
	font-size: 14px;
	color: #0066cc;
	background-color: transparent;
	border: 0px;
	padding: 0px;
	box-shadow: none;
}
.DynamicButton > span {
	text-decoration: underline;
}

.DynamicButton2,
.DynamicButton2:hover,
.DynamicButton2:active,
.DynamicButton2:focus {
	width: max-content;
	font-family: 'Bold';
	font-size: 14px;
	color: #0066cc;
	background-color: #e5f2ff;
	border: 1px dashed #0066cc;
	border-radius: 100px;
	box-shadow: none;
}

.ModalButton,
.ModalButton:hover,
.ModalButton:active,
.ModalButton:focus {
	padding: 0px 24px;
	margin-bottom: 12px;
	border-radius: 5px;
}

.ErrorButton,
.ErrorButton:hover, 
.ErrorButton:active, 
.ErrorButton:focus {
	height: 36px;
	font-size: 14px;
	border-radius: 5px;
	background-color: #1C75BC;
	color: #FFFFFF;
	border: 1px #1C75BC;
	margin-top: 12px;
}
.DownloadButton, .DownloadButton:hover, .DownloadButton:visited, .DownloadButton:focus, .DownloadButton:active, .DownloadButton a {
	height: 48px;
	width: 100%;
	max-width: 230px;
	font-size: 16px;
	color: #FFFFFF;
	background-color: #1C75BC;
	border: 0;
	border-radius: 5px;
}
.FullWidthButton.ant-btn{
	border: none;
}
.Upload div {
	width: 100%;
}
.Upload .ant-upload {
	display: flex;
	justify-content: center;
}

div > .ant-btn.ant-btn-block {
    width: auto;
}

.ant-radio-group {
	display: flex;
}
.ant-radio-button-wrapper {
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px !important;
	padding: 12px;
	margin-right: 12px;
	border-left-width: 1px;
	white-space: nowrap;
}
.ant-radio-button-wrapper > span {
	min-width: 80px;
	text-align: center;
}

.ant-radio-button-wrapper:not(:first-child)::before {
	display: none !important;
}
.ant-radio-group > .ant-radio-button-wrapper-checked {
	background: #e5f2ff !important;
	color: #0066cc !important;
	border-color: #0066cc !important;
}

.LeftCounterButton {
	position: absolute;
	left: 0px;
	margin-top: 9.5px;
	margin-left: 12px;
	z-index: 1;
}
.RightCounterButton {
	position: absolute;
	right: 0px;
	margin-top: 9.5px;
	margin-right: 12px;
	z-index: 1;
}

.NarrowField {
	height: 40px;
	min-height: 40px !important;
	font-family: 'Bold';
	text-align: center;
	display: flex;
	align-items: center;
	border-radius: 5px !important;
	padding: 0px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.ant-modal-header {
	border-radius: 5px 5px 0px 0px;
}
.ant-modal-content {
	border-radius: 5px;
}
.ant-modal-header {
	padding: 12px 24px;
	background-color: #e5f2ff;
}
.ant-modal-body {
	padding: 12px 24px;
	background-color: #f2f3f7;
}
.WarningModal.ant-modal-body {
	padding: 0px;
	background-color: #ffffff;
}
.OrderSummaryModal .ant-modal-body,
.MapModal .ant-modal-body {
	padding: 0px !important;
}

.SquareModal {
	width: 70vh !important;
}
.SquareModal > .ant-modal-content > .ant-modal-body {
	width: 70vh;
	max-height: calc(70vh - 138px);
	overflow-y: scroll;
}

.RectangleModal {
	width: 70vw !important;
}
.RectangleModal > .ant-modal-content > .ant-modal-body {
	width: 70vw;
	max-height: calc(70vh - 138px);
	overflow-y: scroll;
}

.OrderSummaryModal {
	width: 50vw !important;
}
.OrderSummaryModal > .ant-modal-content > .ant-modal-body {
	width: 50vw;
	max-height: calc(90vh - 108px);
	overflow-y: scroll;
}

.MapModal {
	width: 75vw !important;
}
.MapModal > .ant-modal-content > .ant-modal-body {
	width: 75vw;
	min-height: calc(32.5vw + 108px);
	height: calc(32.5vw + 108px);
	max-height: calc(90vh - 108px);
	overflow-y: scroll;
	background-color: #f5f5f5;
}
.MapModal .ant-form {
	height: 100%;
}

.EmailsField > .ant-select-selector {
	height: 40px;
	min-height: 40px !important;
	font-family: 'Regular';
	display: flex;
	align-items: center;
	border: 1px solid #e0e0e0;
	border-radius: 5px !important;
	color: #333333;
	margin: 0px;
	padding: 0px 12px;
}
.EmailsField .ant-select-selection-overflow {
	height: 36px !important;
	flex-wrap: nowrap;
}
.ant-select-selection-overflow-item-suffix {
	top: unset !important;
}

.OverflowEllipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.MapLabel {
	font-size: 8px !important;
	background-color: #ffffff;
	padding: 0px 4px;
	border-radius: 100px;
}

.Widget {
	background-color: #ffffff;
	border-radius: 10px;
	box-shadow: 0px 0px 50px #e5f2ff;
}

.ant-pagination {
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	margin: 0px !important;
	padding: 12px 0px;
}

/* ==================================== Pagination =========================================== */

.PaginationItem {
	border: 1px ridge #ffffff;
	text-align: center;
	padding: 6px 10px;
	margin: 0px 4px;
	color: rgba(0, 0, 0, 0.87);
	align-items: center;
	font-size: 13px;
	background-color: #ffffff;
	border-radius: 2px;
}

.PaginationItemArrow {
	border: 1px ridge #ffffff;
	text-align: center;
	padding: 7px 8px 5px 8px;
	margin: 0px 4px;
	color: rgba(0, 0, 0, 0.87);
	align-items: center;
	font-size: 13px;
	background-color: #ffffff;
	border-radius: 2px;
}

.PaginationItemDots {
	text-align: center;
	padding: 6px 8px;
	margin: 0px 4px;
	color: rgba(113, 113, 113, 0.87);
	align-items: center;
	font-weight: 900;
	font-size: 20px;
	border-radius: 2px;
}

.Disabled {
	cursor: not-allowed;
}

.Selected {
	border: 1px solid #1C75BC;
	font-weight: 600;
	color: #1C75BC;
}

.SelectedDropDown {
	font-weight: 600;
	color: #1C75BC;
}

.PaginationItemDots:hover {
	color: #1C75BC;
}

.OrderTile {
	margin-left: 6px;
	margin-right: 6px;
	border-radius: 12px;
	background-color: #ffffff;
}

.CardTitleDescription {
	font-size: 12px;
	color: #949292
}

.TextWrap {
	white-space: initial;
}

.ant-transfer-list {
	width: 50%;
	height: 500px !important;
}

.ant-list-header{
	padding-top: 0%;
	padding-bottom: 0%;

}
.ant-list-items{
	overflow-y: scroll;
}